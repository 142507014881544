import {
    autorun,
    configure,
    computed,
    observable,
    reaction,
    when,
    makeObservable,
} from 'mobx';
import React, {createContext} from 'react';
import ReactDOM from 'react-dom';

import analytics, {trackEvent} from 'analytics.js';
import http from 'http.js';
import query from 'query.js';
import {format} from 'date-fns';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

import autobind from 'common/decorators/autobind.js';
import ContactModalV2 from 'core/components/v2/ContactModalV2';
import {ModalV2} from '@HealthShareAU/hs-component-library';
import Store from 'core/stores/Store.js';
import {REFERRALS, SITE} from 'core/constants.js';
import {parsePhysician} from 'core/structured_data.js';
import {localStorageGetItem, toggleLoader} from 'core/utils.js';
import {PRAC_LOC_TYPE, SEARCH_LOCALITY} from 'core/constants.js';
import {Mh1BookingApi} from 'core/services/Mh1Service';
import {trackPractitionerList} from '../services/internalEventTracking';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed'});

const GAP_SCHEME_FILTER_DISTANCE_LIMIT = 150;

export class LocationSearchAPIStore extends Store {
    searchURLs = {
        'telehealth': '/api/professionals/v1/search/telehealth-professionals/',
        'regular': '/api/professionals/v1/search/location/',
    };
    constructor(parentStore, hardLimit = 2000) {
        super();

        makeObservable(this, {
            isLoading: observable,
            newResults: observable,
            results: observable,

            APIParams: computed,
            profileIds: computed,
            rankingCoefficients: computed,
            currentMaxDistance: computed,
            url: computed,
        });

        this.parentStore = parentStore;
        // The `hardLimit` comes from `exc` param being added per profile
        // which in hand extends API's URI length to a point which can be
        // bigger than a maximum length of supported by a browser.
        // This is a characteristic of a browser, but our value comes from the
        // lowest denominator (IE) and takes a safe value of 2000 ~ 200 results.
        // https://support.microsoft.com/en-au/help/208427/maximum-url-length-is-2-083-characters-in-internet-explorer
        this.HARD_LIMIT = hardLimit;

        reaction(
            () => this.parentStore.searchParams,
            (searchParams, reaction) => {
                this.resetAPI();
                if (this.isAllowedToFetch(searchParams)) {
                    this.fetchResults(searchParams);
                }
            },
        );
        reaction(
            () => this.results,
            (results) => {
                this.validateNoDoubledResults(
                    results.map((result) => result.id),
                );
            },
        );
    }

    next = undefined;
    page = 1;
    isLoading = false;
    newResults = [];
    results = [];

    // TODO: Add a max distance limit so that we don't get WA cardiologist on Sydney search.

    /*
     * Returns relevant API endpoint to fetch results from
     * depending on directory type
     */
    get url() {
        if (this.parentStore.telehealthDirectory) {
            return this.searchURLs['telehealth'];
        } else {
            return this.searchURLs['regular'];
        }
    }

    /**
     * Returns a set of adjusted query parameters that can be passed into the API.
     */
    get APIParams() {
        const APIParams = this.parentStore.searchParams;
        // When querying for data exclude the profiles we have already displayed
        // That should only happen when asking for a non health fund data
        if (
            this.results.length > 0 &&
            (!APIParams.health_fund || this.results[0].rankingCoeff)
        ) {
            APIParams.exc = this.profileIds;
        } else {
            // `page` cannot be used with random results, which are indirectly triggered
            // by the presence of the `exc` parameter
            if (this.page > 1) {
                APIParams.page = this.page;
            }
        }
        return APIParams;
    }

    /**
     * Filter out results polluted with practice location ids due to merging of results.
     */
    get profileIds() {
        return this.results.map((result) => result.id);
    }

    get rankingCoefficients() {
        return this.results.map((result) => result.rankingCoeff);
    }

    /**
     * Returns max distance from all current results.
     */
    get currentMaxDistance() {
        return this.results[this.results.length - 1]?.distance;
    }

    /**
     * Returns true current API request have reached the length limit, false otherwise.
     */
    hasReachedLimit(searchParams) {
        if (this.getFullURL(searchParams).length > this.HARD_LIMIT) {
            return true;
        }
        return false;
    }

    /**
     * Returns true if the API is allowed to fetch more results.
     */
    isAllowedToFetch(searchParams) {
        return (
            !this.isLoading &&
            this.parentStore.isValidCall &&
            !this.hasReachedLimit(searchParams)
        );
    }

    /**
     *  Returns a reconstructed URL path for the API get request.
     */
    getFullURL(searchParams) {
        return `${this.url}?${http.parameterise(searchParams)}`;
    }

    /**
     * Clears out all the results stored by previous calls and restores default params.
     */
    resetAPI() {
        this.updateStore({
            next: undefined,
            page: 1, // So that other request do not inherit this value
            newResults: [], // Otherwise tracking will be done twice on HF refresh
            results: [],
        });
    }

    /**
     * Triggers a reaction that loads more results into the store.
     */
    async loadMoreResults() {
        if (this.isAllowedToFetch(this.APIParams)) {
            this.updateStore({
                page: this.page + 1,
            });
            await this.fetchResults(this.APIParams);
        }
    }

    /**
     * Fetches location search results and stores them in the store.
     * @param {object} queryParams
     */
    @autobind
    async fetchResults(queryParams) {
        this.updateStore({isLoading: true});

        let response;
        try {
            response = await http.get({
                url: this.url,
                data: queryParams,
            });
        } catch (error) {
            this.parentStore.rethrowError(
                `Search Results API returned ${error}`,
            );
        } finally {
            this.storeSearchResults(response);
        }

        this.updateStore({isLoading: false});
    }

    /**
     * Stores the results of the API call into the store.
     * @param {object} response - returned from the API call
     */
    async storeSearchResults(response) {
        const data = snakeToCamelObjectKeys(response);
        const results = [...this.results].concat(data.results);

        this.updateStore({
            newResults: data.results,
            next: data.next,
            results,
        });
    }

    /**
     * Runs through the ids of results presented on the page and validates
     * that there are not repeated values.
     * @param {number[]} listOfIds
     */
    validateNoDoubledResults(listOfIds) {
        if (listOfIds.length !== new Set(listOfIds).size) {
            this.parentStore.rethrowError('Directory results have duplicates');
        }
    }
}

export class PracticeLocationSearchResultsAPI extends Store {
    url = '/api/practices/v1/practice-locations/search/location/';
    isLoading = false;
    results = [];

    constructor(parentStore) {
        super();

        makeObservable(this, {
            isLoading: observable,
            results: observable,
            isValidCall: computed,
        });

        this.parentStore = parentStore;

        reaction(
            () => this.parentStore.searchParams,
            (searchParams) => {
                this.resetAPI();
                // if the specialty is doesn't allow this
                if (this.isValidCall) {
                    this.fetchResults(searchParams);
                }
            },
        );
    }

    /**
     * Returns true if minimum required variables to make a valid request are present.
     */
    get isValidCall() {
        return (
            this.parentStore.specialty.displayPracticeListings &&
            this.parentStore.isValidCall
        );
    }

    /**
     * Clears out all the results stored by previous calls and restores default params.
     */
    resetAPI() {
        this.updateStore({
            isLoading: false,
            results: [],
        });
    }

    /**
     * Fetches practice location search results and stores them in the store.
     * @param {object} queryParams
     */
    @autobind
    async fetchResults(queryParams) {
        this.updateStore({isLoading: true});

        let response;
        const data = {
            ...queryParams,
            'include_practitioners': false,
        };
        if (this.parentStore.telehealthDirectory) {
            data['offers_telehealth'] = true;
        }
        try {
            response = await http.get({
                url: this.url,
                data,
            });
        } catch (error) {
            this.parentStore.rethrowError(
                `Practice Location API returned ${error}`,
            );
        } finally {
            this.storeResults(response);
        }
        this.updateStore({isLoading: false});
    }

    /**
     * Stores received response in a form of results.
     * @param {object} response
     */
    storeResults(response) {
        const data = snakeToCamelObjectKeys(response);
        this.updateStore({results: data.results});
    }

    /**
     * Returns a results filtered by max distance.
     * @param {number} maxDistance
     */
    resultsBelowDistance(maxDistance) {
        return this.results.filter((r) => r.distance <= maxDistance);
    }
}
const ANY_ID = 'any';
export const ANY_LANGUAGE = 'Any language';
export const ANY_GENDER = 'Any gender';
export const ANY_LANGUAGE_DEFAULT_OPTION = {
    id: ANY_ID,
    isDefaultOption: true,
    label: 'Language',
    name: ANY_LANGUAGE,
};
const anyGenderOption = {
    id: ANY_ID,
    isDefaultOption: true,
    label: 'Gender',
    name: ANY_GENDER,
};
export const TOP_LANGUAGES = [
    {...ANY_LANGUAGE_DEFAULT_OPTION},
    {
        id: 'HI',
        name: 'Hindi',
    },
    {
        id: 'ZH',
        name: 'Mandarin',
    },
    {
        id: 'CN',
        name: 'Cantonese',
    },
    {
        id: 'AR',
        name: 'Arabic',
    },
    {
        id: 'FR',
        name: 'French',
    },
];

export default class DirectoryStore extends Store {
    telehealthDirectory = false;
    toggleDirectoryURL = '';

    /* Directory Filters */
    genders = [
        {...anyGenderOption},
        {
            name: 'Female',
            id: 'F',
        },
        {
            name: 'Male',
            id: 'M',
        },
    ];

    selectedGender = anyGenderOption;
    genderFilterIsOpen = false;

    languages = [];
    selectedLanguage = ANY_LANGUAGE_DEFAULT_OPTION;
    shouldUseInitialLanguage = true;
    languageFilterIsOpen = false;

    filterByTelehealth = false;
    /* end Directory Filters */

    isStaff = false;

    specialty = {};
    interest = {};
    locality = {};

    // Used to indicate that the call for banner ads has finished
    bannerAdsHaveLoaded = true;
    bannerAds = [];
    bannerURLs = {
        'telehealth': '/api/professionals/v1/telehealth-ba/',
        'regular': '/api/professionals/v1/ba/',
    };

    practiceLocationResults = undefined;
    practitionerListEventId = null;
    practitionerListEventResolved = null;
    practitionerListEventTracked = new Promise((resolve) => {
        this.practitionerListEventResolved = resolve;
    });
    searchResultsError = false;

    inNetworkHospitalFilter = false;
    promotedGapSchemePractitionerLength = null;
    // Gap scheme info should be shown by default if a gap scheme is present
    showOnlyGapScheme = true;

    // We want to load the structured data only once
    structuredDataIsLoaded = false;

    // MH1 booking related
    availabilitySummary = [];
    isMh1Running = false;

    constructor(rootStore) {
        super();
        this.rootStore = rootStore;

        makeObservable(this, {
            selectedGender: observable,
            genderFilterIsOpen: observable,
            selectedLanguage: observable,
            languageFilterIsOpen: observable,
            languages: observable,
            specialty: observable,
            interest: observable,
            locality: observable,
            bannerAdsHaveLoaded: observable,
            bannerAds: observable,
            isStaff: observable,
            inNetworkHospitalFilter: observable,
            showOnlyGapScheme: observable,
            promotedGapSchemePractitionerLength: observable,
            practiceLocationResults: observable,
            searchResultsError: observable,
            structuredDataIsLoaded: observable,
            telehealthDirectory: observable,
            toggleDirectoryURL: observable,
            filterByTelehealth: observable,
            availabilitySummary: observable,
            isMh1Running: observable,

            shouldLoadStructuredData: computed,
            searchByGapScheme: computed,
            isHealthFundParam: computed,
            results: computed,
            searchParams: computed,
            regularBannerAds: computed,
            healthFundBannerAds: computed,
            uriEncodedSpecialty: computed,
            isValidCall: computed,
            bannerAdsURL: computed,
            telehealthDirectoryLoading: computed,
        });

        this.locationSearchAPI = new LocationSearchAPIStore(this);
        this.practiceLocationAPI = new PracticeLocationSearchResultsAPI(this);

        autorun(() => this.fetchLanguages());

        when(
            () => this.shouldLoadStructuredData,
            () => this.addStructuredData(),
        );

        reaction(
            () => ({
                profileIds: this.locationSearchAPI.profileIds,
                rankings: this.locationSearchAPI.rankingCoefficients,
                bannerAdsHaveLoaded: this.bannerAdsHaveLoaded,
            }),
            (data, reaction) => {
                if (data.bannerAdsHaveLoaded && data.profileIds.length > 0) {
                    this.trackPractitionerListEvent(
                        data.profileIds,
                        data.rankings,
                    );
                }
            },
        );

        reaction(
            () =>
                this.specialty.id &&
                this.locality?.id &&
                this.rootStore?.healthFundStore?.gapSchemeId,
            () => this.prefetchPractitionersCountForPromotedGapScheme(),
        );

        reaction(
            () => this.searchParams,
            () => {
                if (this.isValidCall && this.bannerAdsHaveLoaded) {
                    this.fetchBannerAds();
                }
            },
            {name: 'Fetch banner ads'},
        );

        reaction(
            () => this.bannerAds,
            async () => {
                await this.fetchBookingAvailability(this.bannerAds);
            },
        );

        reaction(
            () =>
                this.bannerAdsHaveLoaded && !this.locationSearchAPI.isLoading,
            (loaded) => {
                toggleLoader(loaded);
            },
        );

        reaction(
            () =>
                this.isValidCall &&
                this.specialty?.offersTelehealth &&
                this.searchParams,
            () => this.fetchDirectoryResultsURL(),
            {name: 'Fetch directory results page URL'},
        );
    }

    get initialLanguageCode() {
        return query.parse().language || null;
    }

    /**
     * Should only load structure data once per page load.
     */
    get shouldLoadStructuredData() {
        return (
            !this.structuredDataIsLoaded &&
            this.locationSearchAPI.results.length > 0
        );
    }

    @autobind
    addStructuredData() {
        try {
            const script = document.createElement('script');
            script.type = 'application/ld+json';
            script.textContent = JSON.stringify(
                this.constructStructuredData(),
            );
            document.head.appendChild(script);
        } catch (error) {
            new Error(`Error adding structured data has occurred ${error}`);
        } finally {
            this.updateStore({structuredDataIsLoaded: true});
        }
    }

    // Convert results into JSON-LD format
    @autobind
    constructStructuredData() {
        const {results} = this.locationSearchAPI;
        const ldResults = {
            '@context': 'https://schema.org',
            '@graph': [],
        };

        results.forEach((result) => {
            // It assumes that only PracticePositions are in the results
            ldResults['@graph'].push(
                parsePhysician({
                    address: result.practice.address,
                    avatar: result.avatar,
                    clinic: {
                        address: {
                            locality: result.practice.localityName,
                            region: result.practice.localityState,
                            address: result.practice.address,
                        },
                        name: result.practice.name,
                        phoneNumber:
                            result.practice.phones.length &&
                            result.practice.phones[0].number,
                    },
                    name: result.displayName,
                    phoneNumber: result.phone,
                    specialty: this.specialty.name,
                    url: result.profileUrl,
                }),
            );
        });
        return ldResults;
    }

    get searchByGapScheme() {
        const {
            gapSchemeInHealthFund,
            gapSchemeId,
        } = this.rootStore?.healthFundStore;
        // Order here is important to ensure the searchParams get updated when
        // health fund is changed to one that has promoted gap schemes
        return gapSchemeInHealthFund && this.showOnlyGapScheme && gapSchemeId;
    }

    get isHealthFundParam() {
        return (
            this.rootStore?.healthFundStore.healthFund?.id &&
            !this.searchByGapScheme
        );
    }

    /**
     * Returns true if minimum required variables to make a valid request are present
     */
    get isValidCall() {
        const {specialty, locality, state, telehealthDirectory} = this;
        return !!(
            specialty.id &&
            // regular directory, locality is required
            ((!telehealthDirectory && locality?.id) ||
                // telehealth directory, state is requied
                (telehealthDirectory && state?.name))
        );
    }
    /**
     * Returns combined results from location and practice location APIs.
     */
    get results() {
        let results = [];
        // 1. Get practice positions and max distance value in that set of results
        let ppResults = this.locationSearchAPI.results;
        let plResults = this.practiceLocationAPI.results.slice();
        const {
            currentMaxDistance,
            isLoading: isLocationSearchLoading,
        } = this.locationSearchAPI;

        // 2. If applicable transform relevant PP -> PL
        if (this.specialty.displayPracticeListings) {
            ppResults = this.reformPracticePositionResults(ppResults);
        }

        if (currentMaxDistance) {
            plResults = this.practiceLocationAPI.resultsBelowDistance(
                currentMaxDistance,
            );
        }
        // 3. If a practice location listing exists include merge into results
        //    that only includes practice location without PP
        if (ppResults && plResults) {
            // wait untill ppResults returns
            if (plResults.length && isLocationSearchLoading) {
                results = ppResults;
            } else {
                results = this.merge(ppResults, plResults);
            }
        }
        // 4. Filter duplicates with type of PP and PL
        const parsedResults = this.filterDuplicates(results);
        if (parsedResults.length) {
            this.fetchBookingAvailability(parsedResults);
        }
        return parsedResults;
    }

    async fetchBookingAvailability(results) {
        let availabilityAPIResponse = [];
        const mh1 = new Mh1BookingApi();
        try {
            // fetch availability
            availabilityAPIResponse = await mh1.fetchAvailabilitySummary(
                results,
            );
        } catch (error) {
            this.updateStore({
                isMh1Running: false,
            });
            return;
        }

        const merged = [
            ...availabilityAPIResponse,
            ...this.availabilitySummary,
        ].reduce((acc, current) => {
            if (!acc.some((item) => item.id === current.id)) {
                acc.push(current);
            }
            return acc;
        }, []);

        this.updateStore({
            availabilitySummary: merged,
            isMh1Running: true,
        });
    }

    /**
     * Filter out duplicates with respect to a type of result.
     * Types include practice position and practice location.
     * @param {object[]} results
     */
    filterDuplicates(initialResults) {
        let currentResult;
        let currentId;
        const ids = {};
        const results = [];
        for (let i = 0; i < initialResults.length; i++) {
            currentResult = initialResults[i];
            // Prefix ids of other types in case of overlap
            if (currentResult.type === PRAC_LOC_TYPE) {
                currentId = `pl${currentResult.id}`;
            } else {
                currentId = currentResult.id;
            }

            if (!ids[currentId]) {
                results.push(currentResult);
            }
            ids[currentId] = 1; // Value doesn't matter at this stage
        }
        return results;
    }

    /**
     * Returns the same array of results but practice positions that
     * have practice location will be substitute with said location.
     * @param {object[]} results
     */
    reformPracticePositionResults(results) {
        return results.map((result) => {
            if (this.telehealthDirectory || this.filterByTelehealth) {
                return result.practiceLocation?.offersTelehealth
                    ? result.practiceLocation
                    : result;
            } else {
                return result.practiceLocation || result;
            }
        });
    }

    /**
     * Order that only applies to practice locations since we don't want
     * to influence the order generated by the location API.
     * @param {object} r1 - either profile or practice location
     * @param {object} r2 - either profile or practice location
     */
    order(r1, r2) {
        if (r2.type === PRAC_LOC_TYPE || r1.type === PRAC_LOC_TYPE) {
            if (r1.distance === r2.distance) {
                if (r1.type === PRAC_LOC_TYPE) {
                    return 1;
                } else {
                    return -1;
                }
            }
        }
        return r1.distance - r2.distance;
    }

    /**
     * Merges arrays of results, making sure that practice listings are injected
     * at a correct distance.
     * @param {*} profileResults
     * @param {*} practiceResults
     */
    merge(profileResults = [], practiceResults = []) {
        const results = profileResults.concat(practiceResults);
        if (!this.telehealthDirectory) {
            // Apply ordering for regular directory only
            // as telehealth directory should always be randomized
            results.sort(this.order);
        }
        return results;
    }

    // combines the query params into an object
    get searchParams() {
        const queryParams = {
            specialty: this.specialty.id,
            show: this.rootStore.paramStore?.showParam,
        };
        if (this.locality) {
            queryParams.locality = this.locality?.id;
            queryParams.state = this.locality?.state?.name;
        }
        if (this.state) {
            queryParams.state = this.state?.name;
        }
        if (this.selectedGender.id != ANY_ID) {
            queryParams.gender = this.selectedGender.id;
        }
        if (this.selectedLanguage.id == ANY_ID) {
            if (this.initialLanguageCode && this.shouldUseInitialLanguage) {
                queryParams.language = this.initialLanguageCode;
            }
        } else {
            queryParams.language = this.selectedLanguage?.id;
        }
        if (this.interest?.id) {
            queryParams.interest = this.interest.id;
        }
        if (this.isHealthFundParam) {
            queryParams.health_fund = this.rootStore?.healthFundStore?.healthFund?.id; // eslint-disable-line
        }
        if (this.searchByGapScheme) {
            queryParams.gap_scheme = this.rootStore?.healthFundStore?.gapSchemeId; // eslint-disable-line
            // max_distance set to 150 to limit the scope of a gap_scheme search
            queryParams.max_distance = GAP_SCHEME_FILTER_DISTANCE_LIMIT; // eslint-disable-line
        }
        if (this.inNetworkHospitalFilter) {
            queryParams.in_network_hospital_filter = this.inNetworkHospitalFilter;
        }
        if (this.filterByTelehealth) {
            // Note: not to confuse with Telehealth Directory
            // For banners ads, fetch banners that are related to practices/practitioners
            //    that offer telehealth but not Telehealth banners
            // For regular listings, fetch practitioners offering telehealth
            //     but not practitioners practicing in Telehealth-only practices
            queryParams.offers_telehealth = this.filterByTelehealth;
        }

        return queryParams;
    }

    get regularBannerAds() {
        return this.bannerAds.filter((bannerAd) => !bannerAd.healthFund);
    }

    get healthFundBannerAds() {
        return this.bannerAds.filter((bannerAd) => bannerAd.healthFund);
    }

    get uriEncodedSpecialty() {
        return encodeURI(this.specialty.name.toLowerCase());
    }

    @autobind
    async trackPractitionerListEvent(profileIds, rankingCoefficients) {
        const {page} = this.locationSearchAPI;

        // Internal event tracking
        const practitionerListEventId = await trackPractitionerList(
            this,
            profileIds,
        );
        this.updateStore({practitionerListEventId});

        // Track the ranking coefficients
        const rankData = {
            practitionerListEventId: practitionerListEventId,
            pageNumber: page > 1 ? page : 1,
            practitionerIds: profileIds,
            rankingCoefficients: rankingCoefficients,
        };

        trackEvent('/api/a/v2/event/', {
            'event_name': 'practitionerListOrderEvent',
            'event_data': rankData,
        });

        this.practitionerListEventResolved();
    }

    @autobind
    async prefetchPractitionersCountForPromotedGapScheme() {
        const {
            healthFundStore: {gapSchemeId, gapSchemeInHealthFund},
        } = this.rootStore;
        if (!this.specialty || !this.locality || !gapSchemeId) {
            return;
        }
        if (gapSchemeInHealthFund && !gapSchemeId) {
            return;
        }
        const url = '/api/professionals/v1/search/location/';
        const data = {
            specialty: this.specialty.id,
            locality: this.locality?.id,
            'gap_scheme': gapSchemeId,
            'max_distance': GAP_SCHEME_FILTER_DISTANCE_LIMIT,
        };
        const results = await http.get({url, data});
        this.updateStore({
            promotedGapSchemePractitionerLength: results.count,
        });
    }

    @autobind
    async fetchLanguages() {
        let response;
        try {
            response = await http.get({url: '/api/base/v1/languages/'});
        } catch (error) {
            this.rethrowError(`Languages API returned ${error}`);
        } finally {
            if (response) {
                let languages = [...TOP_LANGUAGES];
                // remove already defined languages
                const filteredResponse = response
                    ?.map((language) => {
                        return {'id': language.code, 'name': language.name};
                    })
                    .filter(
                        (value) => !languages.find((l) => l.id === value.id),
                    );
                languages.push(...filteredResponse);

                this.updateStore({languages});

                if (this.initialLanguageCode) {
                    const selectedLanguage = languages.find(
                        (l) => l.id === this.initialLanguageCode,
                    );
                    if (selectedLanguage) {
                        this.updateStore({
                            selectedLanguage,
                            shouldUseInitialLanguage: false,
                        });
                    }
                }
            }
        }
    }

    get bannerAdsURL() {
        if (this.telehealthDirectory) {
            return this.bannerURLs['telehealth'];
        } else {
            return this.bannerURLs['regular'];
        }
    }

    @autobind
    getBookingAvailability(bookingIntegration) {
        let availability;
        if (this.availabilitySummary?.length) {
            availability =
                this.availabilitySummary.find(
                    (d) =>
                        d.id.toString() ===
                        bookingIntegration.bookingId.toString(),
                ) || false;
        }
        return availability;
    }

    @autobind
    async fetchBannerAds() {
        const {
            gapSchemeInHealthFund,
            gapSchemeId,
        } = this.rootStore?.healthFundStore;
        if (gapSchemeInHealthFund && !gapSchemeId) {
            return;
        }
        let data = [];
        let response = [];
        this.updateStore({bannerAdsHaveLoaded: false});
        try {
            response = await http.get({
                url: this.bannerAdsURL,
                data: {
                    ...this.searchParams,
                    // This overrides ParamStore's showParam that checks
                    // cameFromReferrals in sessionStorage. Banner ad taglines should
                    // only check if referrals user or not and display
                    // widget or directory taglines respectively
                    show: this.rootStore.paramStore?.isReferrals
                        ? REFERRALS
                        : SITE,
                },
            });
        } catch (error) {
            this.rethrowError(
                `${
                    this.telehealthDirectory ? 'Telehealth ' : ''
                }Banner Ad API returned ${error}`,
            );
        } finally {
            data = response.map((d) => snakeToCamelObjectKeys(d));
            this.updateStore({bannerAds: data, bannerAdsHaveLoaded: true});
        }
    }

    get telehealthDirectoryLoading() {
        return (
            this.locationSearchAPI.isLoading &&
            !this.locationSearchAPI.results?.length &&
            !this.bannerAdsHaveLoaded
        );
    }

    @autobind
    async fetchDirectoryResultsURL() {
        /*
         * Fetches the directory results page given a combination of
         * specialty, interest, locality, state, and telehealth params
         * ex:
         *   - specialty & locality: directory/cardiologists-in-bondi-2026-nsw/
         *   - specialty, state, & telehealth: directory/psychologists-in-qld/telehealth/
         */
        let response = null;
        const data = {
            ...this.searchParams,
            // Telehealth directory should fetch regular directory URL
            // and vice versa
            telehealth: !this.telehealthDirectory,
        };
        let savedLocality = localStorageGetItem(SEARCH_LOCALITY);
        if (savedLocality) {
            savedLocality = JSON.parse(savedLocality);
        }

        if (this.telehealthDirectory && !data.locality && savedLocality?.id) {
            data.locality = savedLocality.id;
        }
        try {
            response = await http.get({
                url: '/api/directory/v1/directory-results-url/',
                data,
            });
        } catch (error) {
            this.rethrowError(
                `Fetching Directory results URL failed with: ${error}`,
            );
        } finally {
            if (response?.url) {
                this.updateStore({
                    toggleDirectoryURL: response.url,
                });
            }
        }
    }

    @autobind
    renderModal(props) {
        const {healthFund} = this.rootStore?.healthFundStore;
        const newProps = {
            ...props,
            healthFundId: healthFund?.id,
        };
        return ReactDOM.createPortal(
            <ModalV2
                colorTheme={props.colorTheme}
                handleClose={props.closeModal}
                isOpen={open}
                customClass="directory-modal"
            >
                <ContactModalV2 {...newProps} />
            </ModalV2>,
            document.querySelector('#directory'),
        );
    }

    rethrowError(errorStr) {
        this.updateStore({searchResultsError: true});
        throw new Error(errorStr);
    }
}
